import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const Home = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const preventImageDownload = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
  };

  const backgroundImage = isMobile 
    ? `${process.env.PUBLIC_URL}/home/mobile.jpeg`
    : `${process.env.PUBLIC_URL}/home/desktop.jpeg`;

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'calc(100vh - 64px)', // Subtract AppBar height
        width: '100%',
        overflow: 'hidden',
        userSelect: 'none', // Prevent text selection
      }}
      onContextMenu={preventImageDownload} // Prevent right-click
    >
      <Box
        component="img"
        src={backgroundImage}
        alt="Hero"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          pointerEvents: 'none', // Prevent drag and drop
          WebkitTouchCallout: 'none', // Prevent long-press on mobile
        }}
        draggable="false"
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          textAlign: 'center',
          padding: 4,
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to Richard George Photography
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;
